import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';
declare var $: any;
@Component({
  selector: 'app-my-payments',
  templateUrl: './my-payments.component.html',
  styleUrls: ['./my-payments.component.css']
})
export class MyPaymentsComponent implements OnChanges {
  @Output() seedBoolSeeTicket = new EventEmitter<string>();
  @Input() setSeeTicket = false;
  @Input() tickets: any = [];

  seeTicket: any = false;
  global_: any;
  bilhetesSelecionados: any = [];
  loading: any = false;
  constructor(private global: Global,
    public apiService: ApiService,
    private authService: AuthService,
    private alerts: NgxAlertsService,
  ) {
    this.global_ = this.global;
  }

  ngOnChanges() {
    this.seeTicket = this.setSeeTicket;
  }


  exibirIngressos() {
    this.obterMaisIngressos();
  }


  obterMaisIngressos() {
    this.loading = true;
    this.apiService.Get('MeuIngresso/Pagamento').then((res) => {
      this.loading = false;
      this.tickets = res;
    }).catch((err) => {
      this.loading = false;
      this.alerts.notifyError({
        body: `Erro ao tentar realizar buscar dados`,
        title: 'Erro'
      });
    });
  }


  selectTicket(d) {
    this.getPagTickets(d.codigo);
  }

  obterResumo(p) {
    if (p.loading){
      return;
    }
      p.loading = true;
      this.apiService.Get(`MeuIngresso/Pagamento/${p.codigo}`).then((res) => {
        p.loading = false;
        p.bilhetes = res['bilhetes'];
      }).catch((err) => {
        p.loading = false;
        if (err.status == 401) {
          this.authService.logoutUser();
        } else {
  
          this.alerts.notifyError({
            body: `Erro ${err.error}`,
            title: 'Erro'
          });
        }
      });
  }

  getPagTickets(codigo) {
    this.apiService.Get(`Pagamentos/${codigo}/Bilhetes`).then((res) => {

      this.bilhetesSelecionados = res;
      this.seeTicket = !this.seeTicket;
      var elements = $(".btNavUser");
      for (var i = 0; i < elements.length; i++) {
        if (elements[i].classList.contains('btActive')) {
          elements[i].classList.remove('btActive')
        }
      }
      this.seedBoolSeeTicket.emit('true');
    }).catch((err) => {
      if (err.status == 401) {
        this.authService.logoutUser();
      } else {

        this.alerts.notifyError({
          body: `Erro ${err.error}`,
          title: 'Erro'
        });
      }
    });
  }

  getCreditcard(value) {
    if (value.response_mercado_pago) {
      var json = JSON.parse(value.response_mercado_pago);
      //  this.selectBrandCard(json.card, value);
      if (json.card) {
        return `**** **** **** ${json.card.last_four_digits}`;
      } else {
        return '-'
      }
    }
  }
  // selectBrandCard(card, value) {
  //   if (card) {
  //     let bin = card.first_six_digits
  //     window["Mercadopago"].getPaymentMethod({
  //       "bin": bin
  //     }, (status, response) => {
  //       if (status == 200) {
  //         value.brand = response[0].secure_thumbnail;
  //       }
  //     });
  //   }
  // }

  getStatus(value, dado = null) {
    if (value) {
      switch (value) {
        case "PAGO":
          return "APROVADO";
        case "REJEITADO":
          return "REJEITADO";
        case "CRIADO":
          return "CRIADO";
        case "CANCELADO":
          return "CANCELADO";
        case "DEVOLVIDO":
          return "DEVOLVIDO";
        default:
          return value
      }
    }
  }

  cancelarIngresso(ingresso) {
    this.alerts.alertWarning({
      title: 'Deseja realmente cancelar essa compra?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',

    },
      (event) => {

        if (event.value == true) {
          ingresso.loading = true;
          this.deletPagTickets(ingresso);
        }
      },
      (event) => {
        ingresso.loading = true;
      }
    );
  }

  deletPagTickets(ingresso) {
    this.apiService.Delete(`Pagamentos/${ingresso.codigo}`).then((res) => {
      ingresso.loading = false;
      this.getTickets();
    }).catch((err) => {
      ingresso.loading = false;

      if (err.status == 401) {
        this.authService.logoutUser();
      } else {

        this.alerts.notifyError({
          body: `Erro ${err.error}`,
          title: 'Erro'
        });
      }
    });
  }


  getTickets() {
    this.apiService.Get('MeuIngresso/Pagamento/Proximo').then((res) => {
      this.tickets = res;

    }).catch((err) => {
      this.alerts.notifyError({
        body: `Erro ao tentar realizar Login`,
        title: 'Erro'
      });
    });
  }

}
