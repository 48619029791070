import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CEPError, Endereco, NgxViacepService } from '@brunoc/ngx-viacep';
import { NgxAlertsService } from '@ngx-plus/ngx-alerts';
import * as moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Global } from 'src/app/global';
import { ApiService } from 'src/app/service/apiServices';
import { AuthService } from 'src/app/service/auth/auth.service';

@Component({
  selector: 'app-modal-ingresso-posse',
  templateUrl: './modal-ingresso-posse.component.html',
  styleUrls: ['./modal-ingresso-posse.component.css']
})
export class ModalIngressoPosseComponent implements OnInit {
  global_: any;
  loading: any = false;
  ticket: any = {};
  user: any;
  seeEdit: any;
  listsEstate: any = [];
  listsCity: any = [];
  cpfInvalid: any = false;
  regexTelefone = /^\(?(?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
  listSexo: any = [];
  tickets: any = [];
  lista;
  time;
  constructor(private router: Router,
    public apiService: ApiService,
    private alerts: NgxAlertsService,
    private authService: AuthService,
    private viacep: NgxViacepService,
    public global: Global,
    public dialogRef: MatDialogRef<ModalIngressoPosseComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.tickets = data.listaCheckout;
    this.getTimeServidor();

    // this.tickets = [{ "uuid_valor": "4a002da7-91f1-4cc0-9a14-fda417c78c59", "quantidade": 4, "lista_posses": [{ "ingresso": "838003524a002da7-91f1-4cc0-9a14-fda417c78c59", "nome_ingresso": "Vip", "valor_uuid": "4a002da7-91f1-4cc0-9a14-fda417c78c59", "comprador_posse": true }, { "ingresso": "821852874a002da7-91f1-4cc0-9a14-fda417c78c59", "nome_ingresso": "Vip", "valor_uuid": "4a002da7-91f1-4cc0-9a14-fda417c78c59", "comprador_posse": false }, { "ingresso": "593592354a002da7-91f1-4cc0-9a14-fda417c78c59", "nome_ingresso": "Vip", "valor_uuid": "4a002da7-91f1-4cc0-9a14-fda417c78c59", "comprador_posse": false }, { "ingresso": "647515044a002da7-91f1-4cc0-9a14-fda417c78c59", "nome_ingresso": "Vip", "valor_uuid": "4a002da7-91f1-4cc0-9a14-fda417c78c59", "comprador_posse": false }] }, { "uuid_valor": "b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "quantidade": 3, "lista_posses": [{ "ingresso": "36966233b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "nome_ingresso": "Normal", "valor_uuid": "b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "comprador_posse": true }, { "ingresso": "42273850b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "nome_ingresso": "Normal", "valor_uuid": "b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "comprador_posse": false }, { "ingresso": "40308169b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "nome_ingresso": "Normal", "valor_uuid": "b6af9e84-2ce4-4a14-bdaf-e8f392746f63", "comprador_posse": false }] }]
  }

  ngOnInit() {
    this.getUserCliente();
  }

  getTimeServidor() {
    this.apiService.GetV2('DataHora').then(async (res) => {
      this.time = await localStorage.getItem('@DIngress:listaCheckTime');
      var hora: any = res;
      var now: any = new Date(hora);
      var end: any = new Date(this.time);
      var d = new Date(this.time);
      var final10min = new Date(end.setMinutes(d.getMinutes() + 10));
      var distance = end - now;
      if (final10min > now) {
        this.lista = await localStorage.getItem('@DIngress:listaCheck');
        if (this.lista) {
          this.lista = JSON.parse(this.lista);
          if (this.lista.length == this.tickets.length) {
            this.tickets.forEach(tikect_ => {
              var verfi = this.lista.filter(x => x.uuid_valor == tikect_.uuid_valor);
              if (verfi.length > 0) {
                var index = 0;
                tikect_.lista_posses.forEach(item => {
                  var filter = verfi[0].lista_posses[index];
                  if (filter) {
                    item.nome_posse = filter.nome_posse;
                    item.sobrenome_posse = filter.sobrenome_posse;
                    item.email_posse = filter.email_posse;
                    item.cpf_posse = filter.cpf_posse;
                    if (filter.celular_posse) {
                      item.celular_posse = `${filter.celular_posse.codigo_area}${filter.celular_posse.numero}`;
                    }
                    item.comprador_posse = filter.comprador_posse;
                  }
                  index = index + 1;
                });
              }
            });
          }
        }
      } else {
        await localStorage.setItem('@DIngress:listaCheckTime', "");
        await localStorage.setItem('@DIngress:listaCheck', "");
      }
    }).catch((err) => {
      console.log(err)
    });
  }

  closeModal() {
    this.dialogRef.close(null);
  }

  showAlert(msg) {
    this.alerts.notifyError({
      body: `Erro ${msg}`,
      title: 'Erro'
    });
  }

  getUserCliente() {
    this.apiService.Get(`Clientes`).then((res) => {
      this.user = res[0];

    }).catch((err) => {
      if (err.status == 401) {

      } else {
        this.alerts.alertError({
          title: 'Atenção',
          text: `${err.error}`,
        })
      }
    });
  }


  submitAttr(tickets_) {

    if (this.loading) {
      return;
    }

    var arrayTickes = Object.assign([], tickets_);

    this.loading = true;
    var erros = false;
    arrayTickes.forEach((item => {
      var verfi = item.lista_posses.filter(x => x.email_posse == null || x.celular_posse == null || x.cpf_posse == null || x.nome_posse == null || x.sobrenome_posse == null || x.celular_posse == "" || x.cpf_posse == "" || x.email_posse == "" || x.nome_posse == "" || x.sobrenome_posse == "").filter(x => x.tipo != `Dependente`);
      var verfi2 = item.lista_posses.filter(x => x.cpfInvalid == false);
      var verfi3 = item.lista_posses.filter(x => x.nome_posse == null || x.sobrenome_posse == null || x.nome_posse == "" || x.sobrenome_posse == "").filter(x => x.tipo == `Dependente`);


      if (verfi.length > 0) {
        erros = true;
      } else if (verfi2.length > 0) {
        erros = true;
      } if (verfi3.length > 0) {
        erros = true;
      } else {

        item.lista_posses.forEach(element => {
          if (element.tipo != 'Dependente') {
            element.celular_posse_ = {
              codigo_pais: "55",
              codigo_area: element.celular_posse.substring(0, 2),
              numero: element.celular_posse.substring(2)
            };
            element.celular_posse = element.celular_posse_;
            delete element.celular_posse_;
          }
        });

      }
    }));

    if (erros) {
      this.alerts.alertError({
        title: 'Atenção',
        text: `Favor atribuir os ingressos corretamente`,
      }).then((result) => {
        this.loading = false;
      });
      return;
    }

    this.dialogRef.close(tickets_);

  }


  validarCPF(inputCPF, item) {
    if (!inputCPF) {
      this.cpfInvalid = false;
      item.cpfInvalid = false;
      return false;
    }
    var soma = 0;
    var resto;

    if (inputCPF == '00000000000') return false;
    for (var i = 1; i <= 9; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(9, 10))) {
      this.cpfInvalid = false;
      item.cpfInvalid = false;
      return false;
    }

    soma = 0;
    for (i = 1; i <= 10; i++) soma = soma + parseInt(inputCPF.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(inputCPF.substring(10, 11))) {
      this.cpfInvalid = false;
      item.cpfInvalid = false;
      return false;
    }

    this.cpfInvalid = true;
    item.cpfInvalid = true;
    return false;
  }

  attUsuario(item) {
    //verificar se o usuario já possui um ingresso atrelado a ele;
    // var possui = false;
    // this.tickets.forEach(item => {
    //   var verif = item.lista_posses.filter(x => x.cpf_posse == this.user.cpf);
    //   if (verif.length > 0) {
    //     possui = true;
    //   }
    // })
    // if (possui) {
    //   this.alerts.alertError({
    //     title: 'Atenção',
    //     text: `Já existe um ingresso atribuido a esse cpf`,
    //   }).then((result) => {
    //     this.loading = false;
    //   });
    // } else {      
    //   item.nome_posse = this.user.nome;
    //   item.sobrenome_posse = this.user.sobrenome;
    //   item.email_posse = this.user.email;
    //   item.cpf_posse = this.user.cpf;
    //   item.celular_posse = this.user.telefone != null ? `${this.user.telefone.codigo_area}${this.user.telefone.numero}` : null;
    //   item.comprador_posse = true;
    // }    
    item.nome_posse = this.user.nome;
    item.sobrenome_posse = this.user.sobrenome;
    item.email_posse = this.user.email;
    item.cpf_posse = this.user.cpf;
    item.celular_posse = this.user.telefone != null ? `${this.user.telefone.codigo_area}${this.user.telefone.numero}` : null;
    item.comprador_posse = true;
  }

}
